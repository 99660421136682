<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="区域管理名称：" prop="name">
        <div class="itemBox">
          <el-input v-model="ruleForm.name" placeholder="请输入区域管理名称"></el-input>
          <div class="tips">仅用于后台识别，前端不显示</div>
        </div>
      </el-form-item>
      <el-form-item label="区域管理员姓名：" prop="contacts_name">
        <el-input v-model="ruleForm.contacts_name" placeholder="请输入区域管理员姓名"></el-input>
      </el-form-item>
      <el-form-item label="用户openid：" prop="openid">
        <div class="closeUser">
          <div>
            <el-input disabled placeholder="请选择用户" v-model="ruleForm.open_id"></el-input>
            <el-button type="primary" @click="chooseUserFlag = true">选择</el-button>
          </div>
          <div v-if="chooseUserInfo.avatar" class="userInfoBox">
            <img :src="chooseUserInfo.avatar" alt="" />
            <div class="name">{{ chooseUserInfo.nickname }}</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="联系电话：" prop="contacts_phone">
        <el-input v-model="ruleForm.contacts_phone" placeholder="请输入区域管理员电话"></el-input>
      </el-form-item>
      <el-form-item label="管理区域：" prop="area_id">
        <el-cascader v-model="ruleForm.area_id" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" filterable :options="areaList"></el-cascader>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
    <!-- 选择用户弹框 -->
    <el-dialog title="选择用户" :visible.sync="chooseUserFlag" width="1000px">
      <ListComponents v-if="chooseUserFlag" :styleMethon="7" @getListItem="getUserInfo"></ListComponents>
    </el-dialog>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ListComponents from '@/components/listComponents';
export default {
  components: {
    Preservation,
    ListComponents,
  },
  data() {
    function checkArea(rule, value, callback) {
      if (value.length < 2) {
        callback(new Error('请选择管理区域！！'));
      } else {
        callback();
      }
    }
    function checkUser(rule, value, callback) {
      if (!value) {
        callback(new Error('请选择用户！！'));
      } else {
        callback();
      }
    }
    return {
      regionalID: null,
      ruleForm: {
        name: '',
        contacts_name: '',
        contacts_phone: '',
        area_id: [],
        open_id: '',
      },
      rules: {
        name: [{ required: true, message: '请输入区域管理名称', trigger: 'blur' }],
        contacts_name: [{ required: true, message: '请输入区域管理员姓名', trigger: 'blur' }],
        contacts_phone: [{ required: true, message: '请输入区域管理员电话', trigger: 'blur' }],
        area_id: { validator: checkArea, trigger: 'change' },
        open_id: { validator: checkUser, trigger: 'change' },
      },
      // 选择用户弹框
      chooseUserFlag: false,
      chooseUserInfo: {},
      areaList: [],
    };
  },
  created() {
    this.getArea();
    if (this.$route.query.id) {
      this.regionalID = this.$route.query.id;
      this.getRegionalInfo();
    }
  },
  methods: {
    // 获取选中的用户信息
    getUserInfo(data) {
      this.chooseUserInfo = data;
      this.ruleForm.open_id = data.mini_openid;
      this.chooseUserFlag = false;
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    // 点击保存按钮
    preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.regionalID) {
            this.editRegional();
          } else {
            this.addRegional();
          }
        }
      });
    },
    // add 添加区域管理员
    addRegional() {
      let obj = {
        name: this.ruleForm.name,
        area_id: this.ruleForm.area_id[2],
        contacts_name: this.ruleForm.contacts_name,
        contacts_phone: this.ruleForm.contacts_phone,
        open_id: this.ruleForm.open_id,
        user_id: this.chooseUserInfo.id,
      };
      this.$axios.post(this.$api.O2O.regionalAdministrator.addRegional, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('添加成功');
          this.$router.push('/region/regionalAdministratorList');
        } else {
          this.$message.error(res.result);
        }
      });
    },
    // edit 编辑区域管理员
    editRegional() {
      let obj = {
        id: this.regionalID,
        name: this.ruleForm.name,
        area_id: this.ruleForm.area_id[2],
        contacts_name: this.ruleForm.contacts_name,
        contacts_phone: this.ruleForm.contacts_phone,
        open_id: this.ruleForm.open_id,
        user_id: this.chooseUserInfo.id,
      };
      this.$axios.post(this.$api.O2O.regionalAdministrator.editRegional, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('编辑成功');
          this.$router.push('/region/regionalAdministratorList');
        } else {
          this.$message.error(res.result);
        }
      });
    },
    getRegionalInfo() {
      this.$axios
        .post(this.$api.O2O.regionalAdministrator.getRegionalInfo, {
          id: this.regionalID,
        })
        .then(res => {
          if (res.code === 0) {
            this.ruleForm = res.result.info;
            let area_id = this.ruleForm.area_id;
            this.ruleForm.area_id = [this.ruleForm.province_id, this.ruleForm.city_id, area_id];
            this.chooseUserInfo = { avatar: this.ruleForm.avatar, nickname: this.ruleForm.nickname };
          } else {
            this.$message.error(res.result);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  background-color: #fff;
  .el-input,
  .el-cascader {
    width: 400px;
  }
  .itemBox {
    display: flex;
    align-items: center;
    .tips {
      color: #cbcbcb;
      margin-left: 20px;
    }
  }
  .closeUser {
    .el-input {
      width: 330px !important;
    }
    .userInfoBox {
      margin-top: 10px;
      display: flex;
      align-items: center;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-right: 20px;
        border: 1px solid #e4e4e4;
      }
    }
  }
}
</style>
